import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { ToastContextProvider } from '../contexts/ToastContext';
import Layout from '../components/layout/Layout';
import Toast from '../components/common/Toast';
import { lcsDo } from '../utils/lcs';
import { naWcsDo } from '../utils/na';
import { getOpenGraphUrl } from '../utils/url';
import { initializeNClicks } from '../utils/nClicks';
import '../scss/globals.scss';

function App({ Component, pageProps }: AppProps) {
  const { locale, asPath, defaultLocale } = useRouter();
  const { t } = useTranslation('common');

  // PV 집계 (locale 혹은 asPath가 변경될 때마다 trigger)
  useEffect(() => {
    naWcsDo();
    lcsDo();
  }, [locale, asPath]);

  useEffect(() => {
    initializeNClicks();
  }, []);

  return (
    <>
      <Layout>
        <Head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1.0,maximum-scale=2.0,minimum-scale=1.0,user-scalable=no"
          />
          <title>CLOVA Engineering Blog</title>
          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href="https://static-clova.pstatic.net/static/public/app_launch/clova.png"
          />
          <meta name="description" content={t('opengraph_description')} />
          <meta property="og:title" key="og:title" content={t('opengraph_title')} />
          <meta property="og:description" key="og:description" content={t('opengraph_description')} />
          <meta property="og:locale" key="og:locale" content={locale} />
          <meta property="og:url" key="og:url" content={getOpenGraphUrl(asPath, locale, defaultLocale)} />
          <meta property="og:image" key="og:image" content={getOpenGraphUrl('/assets/image/og_image.png')} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={t('opengraph_title')} />
          <meta name="twitter:description" content={t('opengraph_description')} />
          <meta name="twitter:image" content={getOpenGraphUrl('/assets/image/og_image.png')} />
        </Head>
        <ToastContextProvider>
          <Toast />
          <Component {...pageProps} />
        </ToastContextProvider>
      </Layout>

      {/* 3rd-party libraries */}
      <Script src="https://wcs.naver.net/wcslog.js" strategy="beforeInteractive" />
      <Script src="https://ssl.pstatic.net/static/clovablog/libraries/lcslog-v0.12.0.js" strategy="beforeInteractive" />
      <Script src="https://ssl.pstatic.net/static/clovablog/libraries/nclk_v0.7.1.js" strategy="beforeInteractive" />

      {/* runtime env vars (using react-env) */}
      <Script src="/__ENV.js" strategy="beforeInteractive" />
    </>
  );
}

export default appWithTranslation(App);
