import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import useOuterClick from '../../hooks/useOuterClick';
import useAccessibleDropdown from '../../hooks/useAccessibleDropdown';
import nClicks from '../../utils/nClicks';
import styles from './LanguageSelection.module.scss';

const cx = classNames.bind(styles);

export type LanguageSelectionProps = {
  isHeaderHidden: boolean;
};

function LanguageSelection({ isHeaderHidden }: LanguageSelectionProps): JSX.Element {
  const { locales, locale: activeLocale, push, reload } = useRouter();

  // accessibility를 고려한 dropdown
  const languageButtonRef = useRef<HTMLButtonElement>(null);
  const listboxRef = useRef<HTMLUListElement>(null);
  const {
    isDropdownOpen,
    setIsDropdownOpen,
    handleDropdownItemKeydown,
    handleToggleButtonClick,
    handleToggleButtonKeydown,
  } = useAccessibleDropdown(languageButtonRef, listboxRef);

  const handleLanguageButtonClick = (e: React.MouseEvent) => {
    nClicks(e, 'gnb.language');
    handleToggleButtonClick(e);
  };

  // 언어 선택 시 해당 언어의 MainPage로 이동
  const switchLocale = (locale: string) => {
    if (locale === activeLocale) {
      // 동일 locale로 'push'를 하면 외부 라이브러리가 깨지는 이슈가 있어 'reload' 사용
      reload();
    } else {
      push('/', undefined, { locale });
    }
  };

  // scroll event로 인해 header가 숨겨질 때 드롭다운 메뉴도 닫아야 함
  useEffect(() => {
    if (isHeaderHidden) {
      setIsDropdownOpen(false);
    }
  }, [isHeaderHidden, setIsDropdownOpen]);

  // dropdown
  const dropdownDisplay = isDropdownOpen ? 'revert' : 'none';

  const handleLocaleItemClick = (e: React.MouseEvent, locale: string) => {
    e.stopPropagation();
    switchLocale(locale);
    setIsDropdownOpen(false);
  };

  const handleLocaleItemKeydown = (e: React.KeyboardEvent, locale: string, index: number) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      switchLocale(locale);
      setIsDropdownOpen(false);
    } else {
      // accessibility 관련 처리는 useAccessibleDropdown의 handler 사용
      handleDropdownItemKeydown(e, index);
    }
  };

  // outside click event (바깥 쪽 클릭할 경우 dropdown 닫기)
  const closeDropdown = useCallback(() => setIsDropdownOpen(false), [setIsDropdownOpen]); // body에 적용할 event라서 useCallback 사용
  useOuterClick(closeDropdown, isDropdownOpen);

  return (
    <div className={cx('header_language_wrap')}>
      <button
        className={cx('language_switch_button')}
        aria-haspopup={'listbox'}
        aria-controls={'li_language'}
        aria-expanded={isDropdownOpen}
        onClick={handleLanguageButtonClick}
        onKeyDown={handleToggleButtonKeydown}
        ref={languageButtonRef}
      >
        {activeLocale?.toUpperCase() ?? ''}
      </button>
      <ul
        id={'li_language'}
        className={cx('language_dropdown')}
        role={'listbox'}
        aria-labelledby={'select_language'}
        style={{ display: dropdownDisplay }}
        ref={listboxRef}
      >
        {/* locale 변경하는 Next.js 공식 예제 참고
          https://github.com/vercel/next.js/blob/canary/examples/i18n-routing/components/locale-switcher.js */}
        {locales?.map((locale, index) => (
          <li
            key={locale}
            id={locale}
            className={cx('language_dropdown_item')}
            role={'option'}
            aria-selected={activeLocale === locale}
            tabIndex={0}
            onClick={(e) => handleLocaleItemClick(e, locale)}
            onKeyDown={(e) => handleLocaleItemKeydown(e, locale, index)}
          >
            {locale.toUpperCase()}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default LanguageSelection;
