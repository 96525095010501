import { Stringifiable } from '../types/common';

/**
 * 양 쪽 공백은 모두 제거하고 사이 공백은 최대 하나만 유지
 * @param string trim 대상이 될 string
 * @returns 사이 공백이 최대 한 개인 string
 */
export function trimHard(string: string) {
  return string.trim().replace(/\s{2,}/g, ' ');
}

/**
 * truthy value가 들어오면 JSON.stringify를 적용하고, falsy value면 fallback을 반환
 * @param toStringify JSON.stringfy를 적용할 대상
 * @param fallback obj가 falsy value일 때 대신 반환할 값
 */
export function stringifyWithFallback(toStringify?: Stringifiable, fallback: any = undefined) {
  return toStringify ? JSON.stringify(toStringify) : fallback;
}
