import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames/bind';
import { getSearchPageUrl } from '../../utils/url';
import { trimHard } from '../../utils/string';
import nClicks from '../../utils/nClicks';
import useOuterClick from '../../hooks/useOuterClick';
import styles from './SearchBox.module.scss';

const cx = classNames.bind(styles);

type SearchBoxProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function SearchBox({ isOpen, setIsOpen }: SearchBoxProps): JSX.Element {
  const { push } = useRouter();
  const { t } = useTranslation('common');
  const inputRef = useRef<HTMLInputElement>(null);
  const [keyword, setKeyword] = useState('');

  // search box 표시 여부에 따른 effect
  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus(); // search box가 열릴 때 자동으로 input에 focus
    } else {
      setKeyword(''); // 닫힐 때 검색 키워드 초기화
    }
  }, [isOpen]);

  // 검색 관련 작업
  const searchAndClose = () => {
    const trimmedKeyword = trimHard(keyword);
    if (trimmedKeyword.length > 0) {
      const url = getSearchPageUrl(trimmedKeyword);
      push(url);
      setIsOpen(false);
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    nClicks(e, 'gnb.search');

    if (isOpen) {
      searchAndClose(); // search box가 열린 상태에서 클릭할 경우 검색
    } else {
      setIsOpen(true); // search box가 닫힌 상태에서 클릭할 경우 search box 열기
    }
  };

  const handleKeydown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      searchAndClose();
    } else if (e.key === 'Escape') {
      setIsOpen(false);
    }
  };

  // outer click 처리
  const closeSearchBox = useCallback(() => setIsOpen(false), [setIsOpen]); // body에 event listener로 붙여야해서 useCallback 사용
  useOuterClick(closeSearchBox, isOpen);

  return (
    /* [D] search 창이 켜진 경우 '-open' 클래스 추가해주세요. */
    <div
      className={cx('header_search_wrap', { '-open': isOpen })}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
      }}
    >
      <button className={cx('search_button')} onClick={handleClick} aria-labelledby="search-box-blind">
        <span id="search-box-blind" className="blind">
          {t('search_box_blind_text')}
        </span>
      </button>
      <div className={cx('search_input_wrap')}>
        <input
          type="text"
          className={cx('search_input')}
          ref={inputRef}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onKeyDown={handleKeydown}
        />
      </div>
    </div>
  );
}

export default SearchBox;
