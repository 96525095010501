import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const useCSRMediaQuery = (query: string, defaultState: boolean) => {
  const [lazyState, setLazyState] = useState(defaultState);
  const mediaQueryState = useMediaQuery({ query });

  useEffect(() => {
    setLazyState(mediaQueryState);
  }, [mediaQueryState]);

  return lazyState;
};

export const useMobileMediaQuery = () => useCSRMediaQuery('(max-width: 767px)', false);
export const useTabletPCMediaQuery = () => useCSRMediaQuery('(min-width: 768px) and (max-width: 1199px)', false);
export const usePCWideMediaQuery = () => useCSRMediaQuery('(min-width: 1200px)', false);
