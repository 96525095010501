import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames/bind';
import { useMobileMediaQuery, useTabletPCMediaQuery } from '../../hooks/useResponsiveMediaQuery';
import nClicks from '../../utils/nClicks';
import SearchBox from './SearchBox';
import LanguageSelection from './LanguageSelection';
import LogoImage from '../../../public/assets/image/svg/logo_header.svg';
import styles from './Header.module.scss';

const cx = classNames.bind(styles);

type HeaderProps = {
  isHeaderHidden: boolean;
};

function Header({ isHeaderHidden }: HeaderProps): JSX.Element {
  const { pathname } = useRouter();
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletPCMediaQuery();
  const isPC = !(isMobile || isTablet);
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);

  // PostPage에서만 색이 다름
  const postPageRegex = /^\/posts.*/;
  const isPostPage = postPageRegex.test(pathname);

  return (
    <>
      <header className={cx('header', { '-post': isPostPage })}>
        <div className={cx('header_wrap')}>
          {/* mobile, tablet 환경에선 SearchBox 활성화 되었을 때 로고를 숨김 */}
          {(isPC || !isSearchBoxOpen) && (
            <h1 className={cx('header_logo')}>
              <Link href="/">
                <a className={cx('header_logo_link')} onClick={(e) => nClicks(e, 'gnb.home')}>
                  <LogoImage width={185} height={16} viewBox="0 0 220 19" className={cx('header_logo_img')} />
                  <span className={'blind'}>CLOVA Engineering blog</span>
                </a>
              </Link>
            </h1>
          )}
          <div className={cx('header_service_wrap')}>
            <SearchBox isOpen={isSearchBoxOpen} setIsOpen={setIsSearchBoxOpen} />
            <LanguageSelection isHeaderHidden={isHeaderHidden} />
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
