/**
 * window object에 접근이 가능한지 확인 (server-side render시 접근 불가능)
 * @returns window object 접근 가능 여부
 */
export function checkWindowSupport() {
  return typeof window !== 'undefined';
}

/**
 * Clipboard API 구현 되어있는 지 확인 (IE는 미지원)
 * @returns Clipboard API 사용 가능 여부
 */
export function checkClipboardSupport() {
  return !!navigator.clipboard;
}
