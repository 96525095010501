import React, { useContext, useRef, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import ToastContext from '../../contexts/ToastContext';
import styles from './Toast.module.scss';

const cx = classNames.bind(styles);

function clearLastTimeout(timeoutRef: React.MutableRefObject<NodeJS.Timeout | null>) {
  if (timeoutRef.current) {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }
}

function Toast(): JSX.Element {
  const { toastMessage } = useContext(ToastContext);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (toastMessage) {
      // 지난 timeout 제거
      clearLastTimeout(timeoutRef);

      // 4초간 toast 표시 후 숨김
      setIsOpen(true);
      timeoutRef.current = setTimeout(() => {
        setIsOpen(false);
      }, 4000);
    }

    return () => clearLastTimeout(timeoutRef); // unmount 시 timeout 제거
  }, [toastMessage]);

  return (
    /* [D] 노출될 때, '-show' 클래스 추가하고 4초 노출한뒤, '-show' 클래스 제거해주세요. */
    <div className={cx('post_share_toast_wrap', { '-show': isOpen })}>
      <p className={cx('post_share_toast_text')}>{toastMessage?.message ?? ''}</p>
    </div>
  );
}

export default Toast;
