import { useEffect } from 'react';

export default function useOuterClick(eventListener: EventListener, isListenerActive: boolean) {
  useEffect(() => {
    if (isListenerActive) {
      document.body.addEventListener('click', eventListener);
    } else {
      document.body.removeEventListener('click', eventListener);
    }

    return () => {
      // isListenerActive와 무관하게 component가 unmount되면 listener를 제거
      document.body.removeEventListener('click', eventListener);
    };
  }, [isListenerActive, eventListener]);
}
