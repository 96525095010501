import { checkThirdPartyScriptsEnabled } from '../types/environment';
// lcs library는 _app.page.tsx에서 <Script> 사용하여 client-side에서 import

/**
 * LCS 관련 전역 속성
 * @see https://yobi.navercorp.com/logdata-open-docs/20
 */
declare global {
  interface Window {
    // lcs_do의 parameter가 필요할 때 위 링크를 참고하여 lcs_do의 type 개선
    lcs_do?: () => void;
  }
}

export function lcsDo() {
  // 3rd-party 사용 가능성 확인 (window 객체 접근 가능성도 확인)
  if (checkThirdPartyScriptsEnabled()) {
    // lcs script는 _app.page.tsx에서 <Script>를 이용해 삽입 (window object에 lcs_do 함수를 심어놓는 식)
    window.lcs_do?.();
  }
}
