import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import throttle from 'lodash/throttle';
import Header from './Header';
import Footer from './Footer';
import styles from './Layout.module.scss';
import { useRouter } from 'next/router';

const cx = classNames.bind(styles);

type LayoutProps = {
  children: React.ReactNode;
};

function Layout({ children }: LayoutProps): JSX.Element {
  // 아래로 scroll하면 header를 숨기고, 위로 scroll하면 다시 표시
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const lastScrollY = useRef(typeof window !== 'undefined' ? window.scrollY : 0);

  const handleScroll = throttle(() => {
    // 1. 이전 scroll 값과 동일한 값이 들어온 경우 무시 (hash link로 이동 시 불필요한 스크롤 이벤트 발생하는 경우 존재)
    // 2. iOS의 elastic scroll 때문에 scrollY 값이 음수가 되거나 전체 높이보다 높게 넘어가는 경우가 있으므로
    //    그 사이 값일 때만 scroll event를 적용하도록 제한
    if (
      window.scrollY !== lastScrollY.current &&
      window.scrollY >= 0 &&
      window.scrollY + window.innerHeight <= document.body.scrollHeight
    ) {
      setIsHeaderHidden(lastScrollY.current < window.scrollY);
      lastScrollY.current = window.scrollY;
    }
  }, 100);

  const { pathname } = useRouter();

  // PostPage에서만 색이 다름
  const postPageRegex = /^\/posts.*/;
  const isPostPage = postPageRegex.test(pathname);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll); // unmount시 event 해제
  }, [handleScroll]);

  return (
    <div className={cx('wrap', { is_hidden: isHeaderHidden }, { '-post': isPostPage })}>
      <div className={cx('header')}>
        <Header isHeaderHidden={isHeaderHidden} />
      </div>
      <main className={cx('main')}>{children}</main>
      <div className={cx('footer')}>
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
