import env from '@beam-australia/react-env';
import { checkWindowSupport } from '../utils/compatibility';
import { checkWmsUserAgent } from '../utils/userAgent';
import { Nullable } from './common';

// FYI. singleton 변수를 가지고 있긴 하지만 type 선언과 결합이 강하기 때문에 types 폴더에서 관리

/**
 * deployment
 */
export type N2cTag = string | undefined;
let n2cTag: Nullable<N2cTag> = null; // singleton

export function getN2cTag(): N2cTag {
  if (n2cTag === null) {
    n2cTag = process.env.N2C_TAG || undefined; // falsy value는 모두 undefined로 변환
  }
  return n2cTag;
}

export type NeloTxtToken = string | undefined;
let txtToken: Nullable<NeloTxtToken> = null; // singleton

export function getNeloTxtToken(): NeloTxtToken {
  if (txtToken === null) {
    txtToken = process.env.NELO_TXT_TOKEN || undefined; // falsy value는 모두 undefined로 변환
  }
  return txtToken;
}

/**
 * node environment
 */
export type NodeEnv = undefined | 'development' | 'production';
let nodeEnv: Nullable<NodeEnv> = null; // singleton

export function getNodeEnv(): NodeEnv {
  if (nodeEnv === null) {
    nodeEnv = process.env.NEXT_PUBLIC_NODE_ENV as NodeEnv;
  }
  return nodeEnv;
}

/**
 * runtime environment
 * - RUNTIME_ENV는 build 시에 잘못된 값이 미리 등록될 수 있으므로 singleton으로 구성하지 않는다.
 *   (on-demand하게 출력)
 */
export type RuntimeEnv = 'development' | 'stage' | 'production';

export function getRuntimeEnv(): RuntimeEnv {
  return (env('RUNTIME_ENV') as RuntimeEnv) || 'development';
}

/**
 * phase
 * - OPEN: 엔지니어링 블로그 오픈 당시 스펙 (deprecated)
 * - EXPANDED: 4번째 포스트 공개 이후부터 유지되고 있는 현재 스펙
 */
export type Phase = 'OPEN' | 'EXPANDED';
let phase: Nullable<Phase> = null; // singleton

export function getPhase(): Phase {
  if (phase === null) {
    phase = (process.env.NEXT_PUBLIC_PHASE as Phase) || 'EXPANDED';
  }
  return phase;
}

/**
 * 현재 환경이 3rd-party script를 구동해도 되는 환경인지 파악
 * @returns 3rd-party script 사용 가능성 여부
 */
export function checkThirdPartyScriptsEnabled() {
  // window object 접근에 불가능 할 경우 early return (ex. server-side render)
  if (!checkWindowSupport()) {
    return false;
  }

  const nodeEnv = getNodeEnv();
  const runtimeEnv = getRuntimeEnv();
  const uaString = window.navigator.userAgent;

  // production 환경이고 user agent가 WMS가(모니터링 툴) 아닌 경우 3rd-party script 사용을 허용
  return nodeEnv === 'production' && runtimeEnv === 'production' && !checkWmsUserAgent(uaString);
}
