import { checkThirdPartyScriptsEnabled, getNodeEnv, getRuntimeEnv } from '../types/environment';
// nClicks library는 _app.page.tsx에서 <Script> 사용하여 client-side에서 import

/**
 * nClicks 관련 전역 속성
 * @see https://yobi.navercorp.com/logdata-open-docs/posts/78
 */
type EventOrItem = React.MouseEvent | React.FocusEvent | HTMLElement;

declare global {
  interface Window {
    g_ssc: string;
    ccsrv: string;
    g_pid: string;
    nclk_v2?: (
      eventOrItem: EventOrItem,
      sArea: string,
      sCid: string,
      sRank: string,
      sExt?: string,
      sPid?: string
    ) => void;
  }
}

export function initializeNClicks() {
  if (checkThirdPartyScriptsEnabled()) {
    window['g_ssc'] = 'clova.engineering';
    window['ccsrv'] = 'cc.naver.com';
  }
}

/**
 * nClicks 호출 함수
 * description: https://yobi.navercorp.com/logdata-open-docs/posts/78#yb-header-nclk_v2-
 * @param eventOrItem 클릭 이벤트 객체 event 또는 이벤트가 발생한 DOM 객체 this
 * @param sArea 클릭 이벤트가 발생한 개체의 영역클릭코드
 * @param fn (optional) nClicks 호출 후 수행할 function
 * @param sCid (optional) 클릭 이벤트가 발생한 개체와 연관된 콘텐츠 ID 또는 GDID
 * @param sRan (optional) 클릭 이벤트가 발생한 아이템의 일련 순서 번호
 * @param sExt (optional) 기타 사용자 정의 값
 * @param sPid (optional) SPA 또는 필요한 경우 클릭 정보를 수집하고 있는 페이지의 id를 지정
 */
export default function nClicks(
  eventOrItem: EventOrItem,
  sArea: string,
  fn?: (...args: unknown[]) => unknown,
  sCid = '',
  sRank = '',
  sExt?: string,
  sPid?: string
) {
  // 3rd-party script가 사용 가능한 환경에서만 nClicks 호출
  if (checkThirdPartyScriptsEnabled() && window.nclk_v2) {
    // sPid 값을 지정하지 않은 경우, pathname을 sPid로 사용 (ex. '/brand?name=pso' -> 'brand')
    const pid = sPid || window.location.pathname.replace(/^\/*(\w+)[?#]*.*/, '$1');
    window.nclk_v2(eventOrItem, sArea, sCid, sRank, sExt, pid);
  }

  if (fn) {
    return fn();
  }
}
