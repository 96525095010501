import { checkThirdPartyScriptsEnabled } from '../types/environment';

/**
 * NA: NAVER Analytics
 * @see https://help.naver.com/support/contents/contents.help?serviceNo=1042&categoryNo=17476
 */

declare global {
  interface Window {
    wcs_do?: () => void;
    wcs_add?: {
      wa: string;
    };
  }
}

// NA script에서 사용하는 wcs_do function에 대한 wrapper function
export function naWcsDo() {
  // 3rd-party 사용 가능성 확인 (window 객체 접근 가능성도 확인)
  if (!checkThirdPartyScriptsEnabled()) {
    return;
  }

  // 네이버 공통키(na_account_id) 지정 (최초 1회)
  if (!window.wcs_add?.wa) {
    window['wcs_add'] = { wa: '11ab12be43d85f0' };
  }

  // NA script는 _app.page.tsx에서 <Script>를 이용해 import (window object에 필수 함수를 심어놓는 식)
  window.wcs_do?.();
}
