import { Router } from 'next/router';

const SERVICE_URL = 'https://engineering.clova.ai';

function getLocalePath(locale?: string, defaultLocale?: string) {
  if (locale) {
    return locale === defaultLocale ? '' : `/${locale}`;
  }
  return '';
}

export function getTagPageUrl(tag: string, page?: number, locale?: string, defaultLocale?: string) {
  // locale (ko일 경우 생략)
  let url = getLocalePath(locale, defaultLocale);

  // tag
  const encodedTag = encodeURI(tag);
  url += `/tags/${encodedTag}`;

  // page (1 페이지일 경우 생략)
  if (page && page > 1) {
    url = appendQueryString(url, 'page', page.toString());
  }
  return url;
}

export function getSearchPageUrl(keyword: string, page?: number, locale?: string, defaultLocale?: string) {
  // locale (ko일 경우 생략)
  const localePath = getLocalePath(locale, defaultLocale);
  let url = `${localePath}/search`;

  // search keyword
  const encodedKeyword = encodeURI(keyword);
  url = appendQueryString(url, 'keyword', encodedKeyword);

  // page (1 페이지일 경우 생략)
  if (page && page > 1) {
    url = appendQueryString(url, 'page', page.toString());
  }
  return url;
}

export function getPostPageUrl(year: string, month: string, slug: string, locale?: string, defaultLocale?: string) {
  let url = getLocalePath(locale, defaultLocale);
  url += `/posts/${year}/${month}/${slug}`;
  return url;
}

export function pushWithQueryStrings(
  push: Router['push'],
  pathname: Router['pathname'],
  asPath: Router['asPath'],
  query: Router['query'],
  locale: string
) {
  return push({ pathname, query }, asPath, { locale });
}

/**
 * OpenGraph에 사용할 url을 구성
 * @param subPath host 뒤에 붙을 경로 (preceeding slash 포함)
 * @param locale locale code, 없을 경우 적용 안함 (preceeding slash 미포함)
 * @param defaultLocale 기본 locale 값, 없을 경우 "ko" 적용
 * @returns 서비스용 도메인으로 이루어진 OpenGraph용 url string
 */
export function getOpenGraphUrl(subPath: string, locale?: string, defaultLocale?: string) {
  let url = SERVICE_URL;
  if (locale && locale !== (defaultLocale ?? 'ko')) {
    url += `/${locale}`; // locale은 preceding slash를 포함하지 않음 (ex. "ko")
  }
  url += subPath; // subPath는 preceding slash를 포함 (ex. "/posts/...")

  return url;
}

export function appendQueryString(url: string, key: string, value: string) {
  const keyValue = `${key}=${value}`;

  // query string이 이미 하나 이상 있는 지 확인 ('?{key}={value}' 형태의 유무 확인)
  if (/\?\w+\=\w+/.test(url)) {
    return `${url}&${keyValue}`;
  }
  return `${url}?${keyValue}`;
}
