import { useCallback, useState } from 'react';

/**
 * dropdown에 accessibility를 고려한 mouse/keyboard event를 적용
 * (전체적으로 dropdown 관련 이벤트는 [NULI의 코드](https://nuli.navercorp.com/tool/waiAria)를 많이 참고)
 * @param toggleButtonRef dropdown을 여닫을 때 사용하는 버튼의 ref
 * @param listboxRef dropdown item을 담고 있는 <ul>의 ref (<ul role='listbox'>)
 * @returns dropdown 상태 값과 accessibility를 고려한 mouse/keyboard event handler들
 */
export default function useAccessibleDropdown(
  toggleButtonRef: React.RefObject<HTMLButtonElement>,
  listboxRef: React.RefObject<HTMLUListElement>
) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const focusOnDropdownItem = useCallback(
    (index: number) => {
      const options = listboxRef.current?.querySelectorAll<HTMLLIElement>('[role="option"]');
      const optionsLength = options?.length ?? 0;
      if (options && optionsLength > 0) {
        const adjustedIndex = index < 0 ? optionsLength - 1 : index >= optionsLength ? 0 : index;
        options[adjustedIndex].focus();
      }
    },
    [listboxRef]
  );

  const setDropdownState = useCallback(
    (isOpen: boolean, shouldFocusOnButton = false) => {
      if (isOpen) {
        // 현재 선택된 옵션에 focus (선택된 것이 없으면 첫번째 옵션에 focus)
        const selectedOption = listboxRef.current?.querySelector<HTMLLIElement>(
          '[role="option"][aria-selected="true"]'
        );
        if (selectedOption) {
          setTimeout(() => selectedOption.focus()); // setTimeout 안하면 toggleButton이 focus를 뺏어감
        } else {
          focusOnDropdownItem(0);
        }
      }
      setIsDropdownOpen(isOpen);

      if (shouldFocusOnButton) {
        toggleButtonRef?.current?.focus();
      }
    },
    [focusOnDropdownItem, listboxRef, toggleButtonRef]
  );

  const handleDropdownItemKeydown = useCallback(
    (e: React.KeyboardEvent, index: number) => {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        focusOnDropdownItem(index - 1);
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        focusOnDropdownItem(index + 1);
      } else if (e.key === 'Tab' || e.key === 'Escape') {
        e.preventDefault();
        setDropdownState(false, true);
      }
    },
    [focusOnDropdownItem, setDropdownState]
  );

  const handleToggleButtonClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation(); // outside click event 적용되는 경우 방지
      e.preventDefault();
      setDropdownState(!isDropdownOpen);
    },
    [isDropdownOpen, setDropdownState]
  );

  const handleToggleButtonKeydown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault();
        setDropdownState(true);
      } else if (e.key === 'Escape') {
        e.preventDefault();
        setDropdownState(false, true);
      }
    },
    [setDropdownState]
  );

  return {
    isDropdownOpen,
    setIsDropdownOpen,
    handleDropdownItemKeydown,
    handleToggleButtonClick,
    handleToggleButtonKeydown,
  };
}
