import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames/bind';
import nClicks from '../../utils/nClicks';
import styles from './Footer.module.scss';

const cx = classNames.bind(styles);

function Footer(): JSX.Element {
  const { locale } = useRouter();
  const { t } = useTranslation('common');

  return (
    <footer className={cx('footer')}>
      <div className={cx('footer_wrap')}>
        <div className={cx('footer_list_wrap')}>
          <ul className={cx('footer_link_list')}>
            <li className={cx('footer_link_item')}>
              <Link href="https://www.ncloud.com/support/question/sales?parentCode=SALES_CLOVA&code=SALES_CLOVA_001">
                <a className={cx('footer_link')} target="_blank" onClick={(e) => nClicks(e, 'footer.business')}>
                  {t('business_inquiry')}
                </a>
              </Link>
            </li>
          </ul>
          <ul className={cx('footer_icon_list')}>
            <li className={cx('footer_icon_item')}>
              <Link href="https://blog.naver.com/clova_ai">
                <a className={cx('footer_link', '-blog')} target="_blank" onClick={(e) => nClicks(e, 'footer.blog')}>
                  <span className={'blind'}>Blog</span>
                </a>
              </Link>
            </li>
            <li className={cx('footer_icon_item')}>
              <Link href="https://www.facebook.com/ClovaAI">
                <a
                  className={cx('footer_link', '-facebook')}
                  target="_blank"
                  onClick={(e) => nClicks(e, 'footer.facebook')}
                >
                  <span className={'blind'}>Facebook</span>
                </a>
              </Link>
            </li>
            <li className={cx('footer_icon_item')}>
              <Link href="https://www.instagram.com/naver_clova/">
                <a
                  className={cx('footer_link', '-instagram')}
                  target="_blank"
                  onClick={(e) => nClicks(e, 'footer.instagram')}
                >
                  <span className={'blind'}>Instagram</span>
                </a>
              </Link>
            </li>
            <li className={cx('footer_icon_item')}>
              <Link href="https://www.youtube.com/channel/UCUuTYvmFcwOg06KnH5xx2sQ">
                <a
                  className={cx('footer_link', '-youtube')}
                  target="_blank"
                  onClick={(e) => nClicks(e, 'footer.youtube')}
                >
                  <span className={'blind'}>YouTube</span>
                </a>
              </Link>
            </li>
          </ul>
        </div>
        <address className={cx('footer_naver_clova')}>
          <Link href="https://clova.ai/">
            <a className={cx('footer_logo_link')} target="_blank" onClick={(e) => nClicks(e, 'footer.clova')}>
              <span className={'blind'}>CLOVA</span>
            </a>
          </Link>
        </address>
      </div>
    </footer>
  );
}

export default Footer;
